.content {
  position: relative;
  width: 99%;
  max-width: 109.6rem;
  margin: 10.5rem auto 15rem;
  background-color: white;
  padding: 4rem;
  border-radius: 1rem;
  z-index: 0;

  @media only screen and (min-width: 768px) {
    padding: 6rem;
  }

  @media only screen and (min-width: 1024px) {
    padding: 7rem;
  }

  @media only screen and (min-width: 1440px) {
    padding: 8rem;
  }
}

.voucherWrapper {
  width: 100%;
  height: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  grid-gap: 3rem;
  margin-top: 7rem;


  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

.voucher {
  min-height: 27rem;
  border-radius: 2rem;
  padding: 2.2rem 2rem;
  transition: box-shadow 0.3s ease, background-size 0.4s ease, background-position 0.4s ease; 
  position: relative;
  overflow: hidden;

  background-image: url('/images/cherry.svg');
  background-size: 4rem;
  background-repeat: no-repeat;
  background-position: 94% 90%;
  filter: grayscale(30%);

  &::before {
    content: '';
    position: absolute;
    top: -150%;
    left: -150%;
    width: 400%;
    height: 400%;
    background: linear-gradient(45deg, transparent, rgba(246, 250, 251, 1), transparent);
    transform: rotate(45deg);
    transition: transform 0.4s ease;
    z-index: 1;
  }

  &::after {
    content: "Available on sso.bluecherry.io";
    position: absolute;
    bottom: 20px;
    right: 20%;
    opacity: 0;
    transform: translateX(20px);
    transition: opacity 0.4s ease, transform 0.4s ease;
    z-index: 2;
    font-size: 2.1rem;
    color: rgba(0, 61, 109, 0.8);
  }

  &:hover {
    filter: grayscale(0%);
    background-size: 7rem;
    background-position: center 70%;
    box-shadow: 0 10px 20px rgba(131, 131, 131, 0.3);
    transform: scale(2);

    &::before {
      animation: diagonalShine 2s ease-out forwards;
    }

    &::after {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

@keyframes diagonalShine {
  0% {
    transform: translate(-150%, 150%) rotate(45deg);
  }
  100% {
    transform: translate(150%, -150%) rotate(45deg);
  }
}

  .voucherTitle {
    position: absolute;
    z-index: 1;
    font-family: "Barlow Medium";
    font-size: 3.5rem;
    top: 4.4rem;
  }

  .voucherType {
    position: absolute;
    z-index: 1;
    font-size: 1.8rem;
  }
  