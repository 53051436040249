.content {
  position: relative;
  width: 99%;
  max-width: 109.6rem;
  margin: 10.5rem auto 15rem;
  background-color: white;
  padding: 4rem;
  border-radius: 1rem;
  z-index: 0;

  @media only screen and (min-width: 768px) {
    padding: 6rem;
  }

  @media only screen and (min-width: 1024px) {
    padding: 7rem;
  }

  @media only screen and (min-width: 1440px) {
    padding: 8rem;
  }
}

.priceWrapper {
  width: 100%;
  background-color: var(--background-color);
  border-radius: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  column-gap: 1rem;
  row-gap: 2rem;
  text-align: left;
  margin-top: 10rem;
}

.priceCard {
  position: relative;
  padding: 3rem 3rem 10rem;
  border-radius: 2rem;
}

.subtitle,
.title {
  text-align: left;
  margin: 0;
  padding: 0;
}

.priceLabel {
  width: fit-content;
  background-color: var(--accent);
  text-transform: uppercase;
  color: white;
  padding: .5rem 2rem;
  border-radius: 3rem;
  margin: 0 0 1rem;
  font-size: 1.6rem
}

.priceLabelGreen {
  @extend .priceLabel;
  background-color: var(--accent-secondary);
}

.priceLabelInvisible {
  height: 3.8rem;
  background-color: var(--background-color);
  margin: 0 0 1rem;
}

.link {
  color: var(--text);
  text-decoration: none;
  border-bottom: solid .2rem var(--text);

  &:hover {
    color: var(--accent);
    border-bottom: solid .2rem var(--accent-secondary);
  }
}

.subtitle {
  font-family: "Barlow Bold";
  font-size: 4.6rem;
  height: 4.6rem;
  color: var(--accent-secondary);

  span {
    font-size: 1.9rem;
    font-family: "Barlow Regular";
    color: var(--accent-secondary);
    margin-left: 1rem;
  }
}

.subtitleBlue {
  @extend .subtitle;
  color: var(--accent);

  span {
    color: var(--accent);
  }
}

.title {
  font-size: 2.6rem;
  font-family: "Barlow SemiBold";
  color: var(--subtitle);
  margin: 3rem 0 2rem;
}

.text {
  color: var(--text);
  line-height: 120%;
  margin: 0;
}

.featureList {
  list-style-type: none;
  display: grid;

  li {
    position: relative;
    font-family: "Barlow Regular";
    font-size: 1.8rem;
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    padding-left: 3.3rem;
    padding-top: .2rem;

    &:before {
      display: block;
      position: absolute;
      left: 0;
      content: "";
      width: 2.7rem;
      height: 2.7rem;
      mask-size: 2.7rem;
      mask-image: url('/images/check.svg');
      background-color: var(--accent);
      margin-right: .5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.featureOption {
  margin: 0 0 1rem;
  font-size: 1.8rem;

  span {
    font-size: 1.2rem;
    background-color: var(--accent);
    color: white;
    text-transform: uppercase;
    margin-right: .8rem;
    padding: .4rem 1rem; 
    border-radius: 2rem;
  }
}

.value {
  font-size: 2rem;
  font-family: "Barlow Light";

  span {
    font-family: "Barlow Medium";
  }
}

.button {
  width: calc(100% - 6rem);
  position: absolute;
  bottom: 3rem;
  background-color: var(--accent);
  color: white;
  font-size: 1.5rem;
  padding: 1.4rem 1.2rem;
  margin-top: 5rem;
  text-align: center;
  text-decoration: none;
  border-radius: 1rem;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
    opacity: 0.95;
  }
}

.buttonQuestions {
  @extend .button;
  position: relative;
}

.buttonSecondary {
  @extend .button;
  background-color: var(--accent-secondary);
}

.priceCardSecondary {
  @extend .priceCard;
  padding: 3rem 3rem 10rem;
  background-color: var(--accent);
  color: white;

  .subtitle {
    font-family: "Barlow SemiBold";
  }

  .subtitle, .subtitle span, .title, .text, .plan {
    color: white;
  }

  .featureList li:before {
    background-color: #1C274C;
  }
}

.questionTitle {
  margin: 7rem 0 3rem;
}

.talk {
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
  margin: 2rem 0 0;
  padding: 3rem;
  border-radius: 2rem;
  width: 100%;
  background-color: var(--background-color);

  .link {
    margin-left: 1rem;
  }
}

@media only screen and (min-width: 1124px) {

  .button {
    max-width: 23rem;
  }

  .priceCard {
  &:nth-child(3),
  &:nth-child(6) {
    background-color: #dfecef;
  }
  }

  .priceCardSecondary {
    width: 31rem;
    height: 59rem;
    position: absolute;
    left: 7.5rem; 
    margin-top: -4rem;
    padding-top: 8rem;
    border-radius: 2rem;
    transition: transform 0.3s, box-shadow 0.3s;

    &:after {
      position: absolute;
      display: block;
      content: 'Included in every plan';
      right: 1.5rem;
      top: 1.5rem;
      background-color: #1C274C;
      padding: 1rem 1.5rem;
      border-radius: 2rem;
      text-transform: uppercase;
      transition: transform 0.3s, box-shadow 0.3s;
    }

    &:hover {
      transform: perspective(1000px) rotateY(-10deg) rotateX(5deg) translateY(22px);

      &:after {
        background-color: #202c57;
        transform: scale(1.1) translateX(-48px) translateY(2px);
      }
    }
  }
}

.buttonWrapper {
  display: grid;
  place-items: center;
}