.mainDocumentation {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 109.6rem;
  margin: 15.5rem auto;
  grid-gap: 2rem;
  padding: 0;
  width: 95%;

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 30% 70%;
    width: 100%;
  }
}

.sidebar {
  position: relative;
  background-color: white;
  border-radius: 1rem;
  padding: 10rem 3rem 3rem;

  .topLogo {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 9rem;
    background-color: #e0f1f6;
    border-radius: 1rem 1rem 0 0;
    display: grid;
    place-items: center;
  }

}

.content {
  border-radius: 1rem;
  position: relative;
  background-color: white;
  padding: 4rem;
  z-index: 0;

  @media only screen and (min-width: 768px) {
    padding: 2rem;
  }

  @media only screen and (min-width: 1024px) {
    padding: 3rem;
  }

  @media only screen and (min-width: 1440px) {
    padding: 4rem;
  }
}

.list {
  padding: 0 .4rem; /* Increased horizontal padding */
  margin: 0;
  list-style: none;

  &:nth-child(1) {
    padding: 0;
  }

  span {
    padding-top: .7rem;
    padding-bottom: .7rem;
    display: inline-block; 
  }
}

.listItem {
  font-size: 1.5rem;
  color: var(--text);
  // padding: 0.5rem 2rem; /* Increased horizontal padding */
  position: relative;

  &:last-child {
    border-bottom: none; 
  }
}

.file {
  color: black;
  font-size: 1.6rem;
  font-family: "Barlow SemiBold";
  padding: 0.5rem 2rem; /* Increased horizontal padding */
  border-radius: 0.3rem;
  margin: 0; 

  &:hover {
    cursor: pointer;
    color: var(--accent-secondary);
    background-color: var(--background-color);
  }
}

.activeFile {
  @extend .file;
  color: var(--accent-secondary);
  background-color: var(--background-color);

  &:hover {
    cursor: pointer;
    color: var(--accent-secondary);
    background-color: #e6ebec;
  }
}

.directoryHeader {
  cursor: pointer;
  display: inline-block;
  font-family: "Barlow Regular";
  padding: 0.5rem;
  border-radius: 0.3rem;
  color: var(--text);
  font-size: 1.6rem;
  transition: background-color 0.2s ease;

  svg {
    margin-right: 1rem;
  }

  &:hover {
    background-color: var(--background-color);
  }
}

.searchWrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin: 1rem 0;

  .searchIcon {
    position: absolute;
    right: 1.5rem;
    z-index: 1;
  }

  .search {
    width: 100%;
    background-color: #F6FAFB;
    color: var(--text);
    padding: 1rem 4rem 1rem 1rem;
    font-size: 1.4rem;
    border-radius: .7rem;
    border: solid .1rem #CDD3D4;
    z-index: 1;
  }
}
