.content {
  position: relative;
  width: 99%;
  max-width: 109.6rem;
  margin: 10.5rem auto 15rem;
  background-color: white;
  padding: 4rem;
  border-radius: 1rem;
  z-index: 0;


  @media only screen and (min-width: 768px) {
    padding: 6rem;
  }

  @media only screen and (min-width: 1024px) {
    padding: 7rem;
  }

  @media only screen and (min-width: 1440px) {
    padding: 8rem;
  }
}

.buttons {
  margin-top: 5rem;
  display: grid;
  place-items: center;

  .secondaryButtons {
    margin-top: 3rem;

    & a {
      margin-right: 1rem;
      text-decoration: none;
      color: var(--text);

      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: var(--accent-secondary);
      }
    }
  }

}
