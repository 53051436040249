.footer {
  padding: 3.2rem 1.6rem;
  display: grid;
  place-content: center;

  ul {
    list-style-type: none;
  }

  @media only screen and (min-width: 768px) {
    padding: 6rem;
  }

  @media only screen and (min-width: 1024px) {
    padding: 8rem 8rem 3rem;
  }
}

.container {
  max-width: 109.6rem;
}

.listWrapper {
  display: grid;
  gap: 2rem;

  /* Logo row */
  &>.logo {
    grid-column: 1 / -1;
    margin-bottom: 2rem;
  }

  grid-template-columns: 1fr;
  grid-template-rows: auto;

  @media only screen and (min-width: 678px) and (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);

    .bluecherry {
      grid-column: 1 / -1;
    }
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 2fr repeat(3, 1fr);

    .bluecherry {
      grid-column: 1 / 2;
    }

    .list {
      grid-column: span 1;
    }
  }
}

.bluecherry {
  margin: 1.6rem 0;
  max-width: 45rem;

  .bluecherryTitle {
    color: var(--subtitle);
    font-family: "Barlow Medium";
    font-size: 1.8rem;
    margin: 0;
    text-align: left;
  }

  .bluecherryText {
    color: var(--text);
    font-size: 1.6rem;
    text-align: left;
    margin: 0;
    line-height: 140%;

    span {
      color: var(--accent-secondary);
    }
  }

  @media (min-width: 768px) {

    .bluecherryTitle,
    .bluecherryText {
      font-size: 1.8rem;
    }
  }
}

.list {
  margin: 1.6rem 0;

  .listTitle {
    color: var(--subtitle);
    font-family: "Barlow Medium";
    margin: 0;
    font-size: 1.6rem;
  }

  .listItem {
    margin-bottom: 0.5rem;

    a {
      color: var(--text);
      font-size: 1.6rem;
      margin-bottom: 1rem;
      text-align: left;
      text-decoration: none;

      &:hover {
        color: var(--accent);
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .listTitle {
      font-size: 1.8rem;
    }

    .listItem a {
      font-size: 1.8rem;
    }
  }
}

.sponsorWrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 10rem;

  .flanders_lion_image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: calc(6.71rem * 2.5);
    height: calc(3.21rem * 2.5);
  }
}


.slab {
  width: 100%;

  .copyright {
    font-family: "Barlow Light";
    font-size: 1.6rem;

    a {
      color: var(--text);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

  }

  .toggle {
    width: 4rem;
    height: 4rem;
  }

  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .copyright {
      display: flex;
      align-items: center;
    }
  }
}