.navigation, .subNavigation {
  transition: transform .4s cubic-bezier(.83,0,.17,1), background-color .4s cubic-bezier(.83,0,.17,1);
}

.navigation {
  width: 100%;
  height: 6.6rem;
  position: fixed;
  top: 0;
  z-index: 2;
  background-color: white;
  box-shadow: box-shadow .3s ease-in-out;
  margin: 0;
}

.hidden {
  transform: translateY(-100%);
}

.subNavigation {
  display: block;
  position: absolute;
  top: 6.6rem;
  width: 100%;
  height: 4rem;
  background: var(--accent);
  z-index: 12;
  margin: 0;
}

.container {
  width: 100%;
  max-width: 112rem;
  margin: 0 auto;
  height: 100%;
  display: flex;
  padding: 1.2rem;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.subContainer {
  @extend .container;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  a {
    color: white;
    font-size: 1.4rem;
  }
}

.subContainer > * {
  display: inline-block;
  margin-right: 1rem;
}

.searchWrapper {
  position: relative;
  display: flex;
  align-items: center;

  .searchIcon {
    position: absolute;
    right: 1.5rem;
    z-index: 1;
  }

  .search {
    width: 23rem;
    background-color: #F6FAFB;
    color: var(--text);
    padding: 1rem 4rem 1rem 1rem;
    font-size: 1.4rem;
    border-radius: .7rem;
    border: solid .1rem #CDD3D4;
    z-index: 1;
  }

  .suggestionsList {
    position: absolute;
    top: 100%; 
    left: 0;
    right: 0;
    background-color: white; 
    border: solid .1rem #CDD3D4; 
    border-radius: .5rem; 
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 2; 
    max-height: 300px; 
    overflow-y: auto; 
    margin-top: .5rem;
    z-index: 13;

    li {
      list-style: none; 
      padding: .5rem 1rem;
      cursor: pointer; 
      color: #000;

      &:hover {
        background-color: #E5F3F7; 
      }

      strong {
        color: #333; 
      }

      ul {
        padding-left: 1rem; 
        margin: 0;
      }
    }
  }
}

.loginLink {
  width: max-content;
  text-align: center;
  text-decoration: underline;
  font-size: 1.4rem;
  color: var(--text);
  padding: 0 2rem;
  z-index: 1;
}

.submitButton {
  z-index: 1;
  text-align: center;
  width: max-content;
  outline: none;
  border: none;
  background-color: var(--accent-secondary);
  color: white;
  padding: 1rem 3rem;
  border-radius: .7rem;
  margin-right: 2rem;
  font-family: "Barlow Regular";
  font-size: 1.4rem;
  text-decoration: none;

  &:hover {
    filter: brightness(0.95);
    cursor: pointer;
  }
}
