.goBack {
  position: absolute;
  top: -4.5rem;
  left: 0;
  display: flex;
  align-items: center;
  position: absolute;
  font-size: 1.6rem;
  color: var(--darkslate);
  text-decoration: none;

  &:hover {
    border-bottom: solid .1rem var(--darkslate);
    text-decoration: underline;
    cursor: pointer;
  }

  svg {
    padding-top: .1rem;
    width: 2rem;
    height: 2rem;
    margin-right: .6rem;
  }
}
