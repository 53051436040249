.logoWrapper {
  display: flex;
  align-items: center;
}

.icon {
  width: 4.5rem;
  height: 4.5rem;
  mask-size: 4.5rem;
  background-color: black;
  margin-right: .8rem;
}

.subtitle, .title {
  margin: 0;
  padding: 0;
  text-align: left;
}

.subtitle {
  font-size: 1.4rem;
  font-family: "Barlow Light";
  text-transform: uppercase;
}

.title {
  font-size: 1.9rem;
  font-family: "Barlow Medium";
}


.text {
  line-height: 140%;
  font-size: 1.9rem;
}
