.wrapper {
  margin-bottom: 5rem;

  .image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 1rem;
    filter: brightness(0.80) contrast(0.95);
    transition: transform  .3s cubic-bezier(.49,-0.01,.45,1), filter  1.1s cubic-bezier(.49,-0.01,.45,1);
    margin-bottom: 2rem;

    &:hover {
      cursor: pointer;
      filter: brightness(1.02) contrast(1.02) hue-rotate(-25deg);
      box-shadow: inset 0 0 0 5px #fff
    }
  }

  .name, .title, .text {
    margin: 0;
    text-align: left;
  }

  .name {
    font-family: "Barlow Light";
    font-size: 1.4rem;
    text-transform: uppercase;
  }
  
  .title {
    font-family: "Barlow SemiBold";
    margin: .8rem 0;
    line-height: 120%;
  }

  .website {
    text-decoration: none;
    color: var(--accent);
    font-size: 2.1rem;
    &:hover {text-decoration: underline;}
  }

  .text {
    margin-top: 1.5rem;
    line-height: 135%;
    font-size: 2.1rem;
  }

  @media only screen and (min-width: 768px) {
    display: flex;

    .image  {
      max-width: 32rem;
      aspect-ratio: 3/5;
    }

    .headerContent {
      width: 100%;
      padding: 2.5rem;
    }
  }

  @media only screen and (min-width: 1024px) {

    .image  {
      aspect-ratio: 1/1;
    }
  }
}

.paragraph {
  margin: 3rem 0;

  .title {
    margin: 0;
    font-family: "Barlow Medium";
    font-size: 2.4rem;
  }

  .text {
    margin-top: 1rem;
  }

  .link {
    display: block;
    color: var(--accent);
    text-decoration: none;
    font-size: 2.1rem;
    margin-top: 1rem;
  }
}

.imageWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 2rem;

  .galleryImage {
    width: 100%;
    height: 100%;
    max-height: 20rem;
    object-fit: cover;
    background-color: var(--background-color);
    border-radius: 1rem;
  }

}
