.button {
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--accent);
  color: var(--background-color);
  border-radius: var(--border-radius);
  top: 1rem;
  right: 1rem;
  padding: 1.5rem 2.5rem;
  font-size: 1.6rem;
  font-family: "Barlow Medium";
  transition: transform  .1s ease-in-out;

  &:nth-child(1) {
    margin-right: 1.2rem;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    opacity: 0.9;

    span {
      display: block;
      color: white;
      filter: brightness(1);
    }
  }

  &:focus {
    cursor: pointer;
    transform: scale(.95);
    filter: brightness(1);
  }

  &:nth-child(1) {
    margin-bottom: 1rem;
  }

  .icon {
    width: 1.7rem;
    height: 1.7rem;
    margin-left: .7rem;

    svg, img {
      width: 1.7rem;
      height: 1.7rem;
    }
  }

  .preIcon {
    @extend .icon;
    margin-left: 0;
    margin-right: .7rem;
  }
}

@media only screen and (min-width:500px) {

  .button {
    width: fit-content;
    margin-right: 1.2rem;
  }

  // .button:nth-child(1) {
  //   margin-bottom: 0;
  // }
}
