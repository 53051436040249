

.content {
  width: 100%;
  background-color: white;
  border-radius: .7rem;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      padding: 1rem 2rem;
      border-bottom: solid .1rem #ececec;
      font-size: 1.4rem;

      &:hover {
        background-color: var(--accent);
        color: white;
        cursor: pointer;
      }

      &:last-child {
        border-bottom: none;
      }
    }

  }
}

.languageToggle {
  max-width: 22rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0rem 2rem;
  border-radius: .7rem;
  box-shadow: 0 0 .5rem .1rem rgba(0, 0, 0, .2);
  height: 4rem;
  background-color: white;
  transition: box-shadow 250ms ease;
  z-index: 16 !important;

  p {
    width: max-content;
    font-family: "Barlow Medium";
    font-size: 1.6rem;
    color: var(--subtitle);
    margin: 0 1rem;
  }

  .chevronIcon {
    transition: transform 250ms ease;
  }

  &[data-state='open'] {
    box-shadow: none;
  }

  &[data-state='open'] .chevronIcon {
    transform: rotate(180deg);
  }

  &:hover {
    cursor: pointer;
  }

}

.languageToggleSmall {
  @extend .languageToggle;
  padding: 0 .6rem;
  height: 3rem;

  .Content {
    margin-top: 4rem;
  }

  p {
    font-size: 1.4rem;
    margin: 0 .3rem;
  }
}

.PopoverContent {
  z-index: 2;

  &:focus-visible, &:focus {
    outline: none;
    border: none;
  }
}

.Content {
  border-radius: 4px;
  padding: 20px;
  width: 260px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-state='open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }
  &[data-state='open'][data-side='right'] {
    animation-name: slideLeftAndFade;
  }
  &[data-state='open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }
  &[data-state='open'][data-side='left'] {
    animation-name: slideRightAndFade;
  }
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}