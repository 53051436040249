.stories {
  position: relative;
  padding-top: 1.5rem;
  place-content: start;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));
  grid-gap: 1.6rem;

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 3rem;
  }
}

.story {
  width: fit-content;
  height: fit-content;
  margin-bottom: 5rem;

  &:hover {
    .image {
      cursor: pointer;
      transform: scale(1.20);
      filter: brightness(1.02) contrast(1.02) hue-rotate(-20deg);
      box-shadow: inset 0 0 0 5px #fff;
    }

    .button {
      background-image: linear-gradient(to right, var(--accent-secondary), var(--accent-secondary)); // Same color for both ends
      background-position: left;  // Start position
      background-size: 200% 100%; // Makes it double the width to create the movement effect
      transition: background-position 0.5s ease; // Smooth transition
      background-color: transparent; // Make background transparent for hover effect

      // Trigger hover effect
      &:hover {
        background-position: right; // Move to the right on hover
      }
    }
  }

  .imageWrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 1rem;
  }

  .image {
    width: 100%;
    height: 100%;
    aspect-ratio: 350 / 235;
    border-radius: 1rem;
    object-fit: cover;
    filter: brightness(0.80) contrast(0.95);
    transition: transform .3s cubic-bezier(.49, -0.01, .45, 1), filter 1.1s cubic-bezier(.49, -0.01, .45, 1);
  }

  .name, .title {
    margin: 0;
    padding: 0;
    text-align: left;
  }

  .name {
    font-family: "Barlow Light";
    font-size: 1.4rem;
    margin: 3rem 0 .3rem;
  }

  .title {
    margin-bottom: 3rem;
    font-size: 2.4rem;
  }

  .description {
    margin-bottom: 2rem;
  }

  .button {
    width: fit-content;
    display: flex;
    align-items: center;
    color: white;
    background-color: var(--accent);
    font-size: 1.6rem;
    padding: 1.2rem 2.1rem;
    border-radius: 3rem;
    text-decoration: none;
    transition: filter 0.3s ease, transform 0.3s ease; 

    svg {
      padding-top: 0.2rem;
      margin-left: 1rem;
      width: 2rem;
    }

    &:hover {
      cursor: pointer;
      filter: brightness(0.95);
      transform: scale(1.02);
    }
  }

  @media only screen and (min-width: 768px) {
    .button {
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: 1024px) {
    max-width: 45rem;
    margin-bottom: 3rem;
  }
}
