.hidden {
  display: none;
};

.logo {
  object-fit: contain;
  aspect-ratio: 110 / 38; 
  width: 100%; 
  height: auto;
  &:hover {cursor: pointer;}
  z-index: 1;
}
