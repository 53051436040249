.Root {
  border-radius: 6px;
  width: 300px;
  background-color: var(--mauve-6);
  box-shadow: 0 2px 10px var(--black-a4);
}

.RootQuestions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ItemQuestions {
  max-width: 70rem;
  margin: 0 auto;
}

.Item {
  overflow: hidden;
  margin-top: 1px;

  &:first-child {
    margin-top: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:focus-within {
    position: relative;
    z-index: 1;
  }
}

.Header {
  all: unset;
  display: flex;
}

.Trigger {
  all: unset;
  font-family: "Barlow Medium";
  font-size: 1.9rem;
  padding: 2rem;
  flex: 1;
  display: flex;
  align-items: center;
  line-height: 1;
  color: var(--text);

  &:hover {
    cursor: pointer;
  }

  img {
    margin-right: 1rem;
  }

  svg {
    margin-left: .6rem;
  }
}

.Content {
  overflow: hidden;
  font-size: 1.8rem;

  &[data-state='open'] {
    animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state='closed'] {
    animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
}

.ContentText {
  position: relative;
  padding: 0rem 2rem;
  line-height: 140%;

  &:before {
    position: absolute;
    left: 0;
    height: 100%;
    content: "";

    border-left: solid .2rem var(--accent-secondary);
  }
}

.Chevron {
  color: var(--accent-secondary);
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);

  .Trigger[data-state='open']>& {
    transform: rotate(180deg);
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
}