.inputWrapper {
    width: 100%;
    height: min-content;
    margin-bottom: .8rem;
  
    & .label {
      font-size: 1.6rem;
      font-family: "Barlow Medium";
      color: var(---background-color);
    }
  
    & .inputContainer {
      margin-top: .8rem;
    }
  
    & .input {
      width: 100%;
      height: 4.8rem;
      outline: none;
      box-shadow: none;

      background-color: var(--background-color);
      font-family: "Barlow Medium";
      font-size: 1.8rem;
      color: var(--accent);
      padding-left: 1.2rem;
      border-radius: .7rem;
      border: solid 0.1rem #D9E7EB;

      &:focus {
        border: solid .2rem var(--accent);
      }

      &::placeholder {
        color: #cddadd;
      }
    }
  
    & .select {
      @extend .input;
      appearance: none;
    }

    & .error {
      @extend .label;
      color: var(--warning);
      margin-top: 1rem;
    }

  // this css is for the background-color when browser is suggesting options in input
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,as
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--accent);
    -webkit-box-shadow: 0 0 0px 1000px var(---background-color) inset;
    transition: background-color 5000s ease-in-out 0s;
  }

}

.textAreaWrapper {
  @extend .inputWrapper;

  & .textarea {
    @extend .input;
    height: 10rem;
    resize: none;
    font-size: 1.6rem;
    padding-left: 1.5rem;
    padding-top: 1.5rem;
  }
}

@media only screen and (min-width: 768px) {
  .inputWrapper { 
    font-size: 1.6rem;

    & .input {
      font-size: 1.8rem;
    }
  }

}

