
.form fieldset {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(27rem, 1fr));
  grid-gap: 1.2rem;
  border: none; 
  padding: 0;  
  margin-top: 3rem;

  & legend {
    font-family: "Barlow Medium";
    font-size: 2.4rem;
    color: var(--accent-secondary);    
    margin-bottom: 1.2rem;
    padding-left: 0;
  }
}

.submitButton {
  outline: none;
  border: none;
  padding: 1.6rem 2.2rem;
  background-color: var(--accent-secondary);
  color: white;
  font-size: 1.6rem;
  border-radius: var(--border-radius);
  margin-top: 4rem;
}

.infoWrapper {
  grid-column: 1 / -1;

  .info p:nth-child(1){
    font-family:"Barlow Medium";
    margin: 0;
  }
  .info p:nth-child(2){
    margin: 0;
  }
}

@media only screen and (min-width: 500px) {
  .form fieldset {
    grid-template-columns: repeat(auto-fill, minmax(31rem, 1fr));
  }
}
