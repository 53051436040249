/* Wrapper for Markdown content */
.markdownContent {
  scroll-behavior: smooth;
  line-height: 1.6; /* Improve readability */
  color: #333; /* Default text color */

  /* Reset some default styles */
  margin: 0;
  padding: 0;

  /* Headings */
  h1, h2, h3, h4, h5, h6 {
    text-align: left;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    color: #2a2a2a; /* Darker color for headings */
    font-weight: bold;
    font-family: inherit;
    line-height: inherit;
  }

  h1 {
    font-size: 2.5rem; /* Larger size for main headings */
    font-family: "Barlow Bold" !important;
  }

  h2 {
    font-size: 2rem; /* Adjusted size for sub-headings */
  }

  h3 {
    font-size: 1.75rem; /* Increased size for h3 */
  }

  h4 {
    font-size: 1.5rem; /* Increased size for h4 */
  }

  h5 {
    font-size: 1.25rem; /* Increased size for h5 */
  }

  h6 {
    font-size: 1rem; /* Slightly larger for h6 */
  }

  /* Paragraphs */
  p {
    margin-top: 0.75rem; /* Increased spacing for readability */
    margin-bottom: 0.75rem;
    line-height: 1.6;
    font-size: 1.6rem; /* Adjusted font size for paragraphs */
    font-family: "Barlow Regular" !important;
    color: #333 !important;
    span {
      color: var(--accent-secondary) !important;
    }
  }

  /* Lists */
  ul, ol {
    margin-top: 0.75rem; /* Increased spacing */
    margin-bottom: 0.75rem;
    padding-left: 2.5rem; /* Adjusted indentation */
    list-style-position: inside;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin-bottom: 0.5rem; /* Increased spacing between list items */
  }

  /* Links */
  a {
    color: #0070f3 !important; /* Blue color for links */
    text-decoration: none; /* Remove underline */
    font-weight: bold;
  }

  a:hover {
    text-decoration: underline;
  }

  /* Code blocks */
  pre {
    background-color: var(--background-color);
    padding: 1.5rem; /* Increased padding */
    border-radius: 8px; /* Rounded corners */
    overflow-x: auto; /* Horizontal scrolling if needed */
    font-size: 1.6rem; /* Increased font size for better readability */
    line-height: 1.6; /* Improved line height */
    white-space: pre-wrap; /* Wrap long lines to fit the container */
    max-width: 100%; /* Ensure it fits within container */
  }

  code {
    background-color: #f5f5f5;
    padding: 0.4rem 0.6rem; /* Increased padding */
    border-radius: 1rem;
    font-size: 1.4rem; /* Adjusted font size for inline code */
  }

  /* Blockquotes */
  blockquote {
    border-left: 4px solid #0070f3; /* Blue border */
    padding-left: 1.5rem; /* Increased padding */
    margin: 1.5rem 0; /* Increased spacing */
    color: #666;
    font-family: "Barlow Regular Italic";
  }

  strong {
    font-family: "Barlow Bold";
  }

  em {
    font-family: "Barlow Regular Italic";
  }

  /* Images */
  img {
    max-width: 100%; /* Responsive images */
    height: auto;
    border-radius: 4px; /* Optional rounded corners */
  }

  /* Tables */
  table {
    width: 100%;
    border-collapse: collapse; /* Remove gaps between borders */
    margin: 1.5rem 0; /* Increased margin */
  }

  th, td {
    padding: 1rem; /* Increased padding */
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background-color: #f5f5f5; /* Header background color */
    font-weight: bold;
  }

  /* Horizontal rules */
  hr {
    border: 0;
    border-top: 1px solid #ddd;
    margin: 1.5rem 0;
  }
}

