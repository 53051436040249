.content {
  position: relative;
  width: 99%;
  max-width: 109.6rem;
  margin: 10.5rem auto 15rem;
  background-color: white;
  padding: 4rem;
  border-radius: 1rem;
  z-index: 0;


  @media only screen and (min-width: 768px) {
    padding: 6rem;
  }

  @media only screen and (min-width: 1024px) {
    padding: 7rem;
  }

  @media only screen and (min-width: 1440px) {
    padding: 8rem;
  }
}


.wrapper {
  margin-bottom: 5rem;

  .image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 1rem;
    margin-bottom: 2rem;

    &:hover {
      cursor: pointer;
      box-shadow: inset 0 0 0 5px #fff
    }
  }

  .title, .text {
    margin: 0;
    text-align: left;
  }

  .title {
    font-family: "Barlow SemiBold";
    margin: .8rem 0;
    line-height: 120%;
  }

  .website {
    text-decoration: none;
    color: var(--accent);
    font-size: 2.1rem;

    &:hover {text-decoration: underline;}
  }

  .text {
    margin-top: 1.5rem;
    line-height: 135%;
    font-size: 2.1rem;
  }


  @media only screen and (min-width: 768px) {
    display: flex;

    .image  {
      max-width: 32rem;
      aspect-ratio: 3/5;
    }

    .headerContent {
      width: 100%;
      padding: 2.5rem;
    }
  }

  @media only screen and (min-width: 1024px) {

    .image  {
      aspect-ratio: 1/1;
    }
  }
}

.paragraph {
  margin: 3rem 0;

  .title {
    margin: 0;
    font-family: "Barlow Medium";
    font-size: 2.4rem;
  }

  .text {
    margin-top: 1rem;
  }
}


.focusPointWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(27rem, 1fr));
  grid-gap: 1.5rem;
  margin-bottom: 6rem;

  & .focusPoint {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    & p {
      min-height: 10rem;
    }

  }
}
.deviceWrapper {
  margin: 3rem 0;

  .deviceImageWrapper {
    margin: 5rem 0;
    padding: 0;
    border-radius: .8rem;
    overflow: hidden;
  }

  .deviceImage {
    width: 100%;
    height: 30rem;
    object-fit: cover;
    border-radius: .8rem;
    filter: brightness(0.80) contrast(0.95);
    transition: transform .3s cubic-bezier(.49, -0.01, .45, 1), filter 1.1s cubic-bezier(.49, -0.01, .45, 1);

    &:hover {
      cursor: pointer;
      transform: scale(1.60);
      filter: brightness(1.10) contrast(1.05);
      box-shadow: inset 0 0 0 5px #fff
    }
  }

  .strong {
    font-family: "Barlow Medium";
    font-size: 1.9rem;
    line-height: 150%;

    span {
      font-size: 1.5rem;
      text-transform: uppercase;
      background-color: var(--accent-secondary);
      color: white;
      padding: .5rem .9rem;
      border-radius: .7rem;
      margin-right: .5rem;
    }
  }

  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5rem;
    place-content: center;
    place-items: center;
  }
}

.deviceWrapperReverse {
  @extend .deviceWrapper;


  @media only screen and (min-width: 768px) {
    grid-template-areas: "first second";

    & div:nth-child(1) {
      grid-area: second;
    }

    & div:nth-child(2) {
      grid-area: first;
    }
  }
}

.imageTitle {
  position: relative;
  margin-top: 15rem;
}

.imageTitle:before {
  position: absolute;
  display: block;
  content: '';
  width: 33.5rem;
  height: 10.3rem;
  background-size: 33.5rem 10.3rem;
  background-image: url('/images/bluecherries.png');
  background-repeat: no-repeat;
  left: 50%;
  transform: translate(-50%, - 10rem);
}

.terminalImage {
  width: 100%;
  height: 100%;
  max-width: 70rem;
  display: block;
  margin: 0 auto;
  border-radius: .8rem;
  margin-bottom: 4rem;
}

.stepWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
  grid-gap: 2rem;
  margin: 6rem 0;
}

.bluecherryButtons {
  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: center;
  }
}

.contentTable {
  @extend .content;

  padding: 0;
  margin: 0 auto;

}

.bluecherrySummary {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  column-gap: 2rem;

  .text {
    margin: 1rem 0;
  }

  @media only screen and (min-width: 768px) {
    padding-bottom: 6rem;
  }

  @media only screen and (min-width: 1024px) {
    padding-bottom: 7rem;
  }

  @media only screen and (min-width: 1440px) {
    padding-bottom: 8rem;
  }
}