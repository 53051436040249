.hero {
  position: relative;
  width: 100%;
  height: 107vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
  user-select: none;
  margin-top: 6.6rem;
  padding: 2rem;
  padding-top: 5rem;
  background-color: rgba(0, 29, 50, 0.4);
  background-repeat: no-repeat;
  background-size: cover;

  .heroVideo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    filter: hue-rotate(-25deg);
  }

  .heroTitle {
    font-family: "Barlow Bold";
    font-size: 5.4rem;
    max-width: 94rem;
    color: white;
    text-align: center;
    margin-top: -13rem;
  }

  .heroIntroduction {
    font-family: "Barlow Light";
    text-align: center;
    color: white;
    max-width: 75rem;
  }

  .heroButtons {

    .sideInfo {
      color: white;
      font-size: 1.5rem;
      text-align: center;
      margin-right: 1rem;
      margin-top: 0.5rem;
    }

    @media only screen and (min-width: 768px) {
      display: flex;
      justify-content: center;
    }
  }

  .moveDown {
    z-index: 1;
    height: 5rem;
    position: absolute;
    bottom: -0.5rem;
    color: var(--accent);
    animation: moveDown 1.8s infinite ease-in-out;

    &:hover {
      cursor: pointer;
      color: var(--accent-secondary);
    }
  }

  @keyframes moveDown {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(1rem);
    }

    100% {
      transform: translateY(0);
    }
  }

  @media only screen and (min-width: 400px) {
   height: calc(99vh - 6.6rem);
    padding-top: 10rem;
  }

  @media only screen and (min-width: 500px) {
    .heroTitle {
      font-size: 6.4rem;
    }
  }

  @media only screen and (min-width: 768px) {
    .heroTitle {
      font-size: 7.4rem;
    }

    .heroIntroduction {
      font-size: 1.8rem;
    }
  }

  @media only screen and (min-width: 1024px) {
    .heroTitle {
      font-size: 8.4rem;
    }

    .heroIntroduction {
      font-size: 2rem;
    }
  }

  @media only screen and (min-width: 1440px) {
    .heroTitle {
      font-size: 9.4rem;
    }

    .heroIntroduction {
      font-size: 2.2rem;
    }
  }
}



.main {
  max-width: 112rem;
  margin: 0 auto;

  .subtitle span {
    color: var(--accent-secondary);
  }
}

.leadArticle {
  position: relative;
  background-color: var(--background-color);
  padding: 5rem  0;
  // padding: 8.3rem;
  margin: -6rem auto;

  .marquee {
    display: none;
    position: absolute;
    margin-top: -6.6rem;
    height: 6.6rem;
    left: 0;
    top: 0;
    z-index: 0;

    .marqueeLogo {
      filter: grayscale(100%);
      object-fit: contain;
      margin-right: 1rem;
      width: 100%;
      max-height: 4rem;
      color: white;
      margin: 0 3rem;
      text-transform: uppercase;
    }

    @media only screen and (min-width: 768px) {
      display: flex;
    }

  }

  .title {
    text-align: center;
    width: 80%;
    margin: 0 auto 3rem;
  }

  .subtitle {
    text-align: center;
  }

}

.splitterStories {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  margin-top: 6rem;
  width: 100%;
  height: .2rem;

  hr {
    display: block;
    width: 100%;
    height: .3rem;
    background-color: #ececec;
    border: none;
  }

  a {
    text-decoration: none;
    color: var(--text);
    display: flex;
    font-size: 1.8rem;
    align-items: center;
    width: max-content;
    margin-left: 1rem;
    font-family: "Barlow Medium";

    svg {
      padding-top: .3rem;
      margin-left: .5rem;
      height: 2rem;
    }

    // display: none;
  }


  @media only screen and (min-width: 768px) {
    margin-top: 11rem;
  }

  @media only screen and (min-width: 1024px) {
    margin-top: 12rem;
  }

  @media only screen and (min-width: 1440px) {
    margin-top: 13rem;
  }
}

.sensorStory {
  margin: 6rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;


  .figure {
    margin: 3rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;


    img {
      max-width: 100%;
      height: auto;
      border-radius: var(--border-radius);
    }

    figcaption {
      font-size: 1.8rem;
      padding: 2rem 0;
      line-height: 150%;
      max-width: 46rem;
    }
  }

  .figureOne {
    flex-direction: column-reverse;
  }

  .ctaSubmit {
    margin-top: 5rem;
    display: grid;
    place-items: center;

    img {
      margin-bottom: 1rem;
    }
  }

  @media only screen and (min-width: 768px) {
    padding: 6rem;
    background-image: url('/images/line2.svg');
    background-repeat: no-repeat;
    background-position: center 47%;
    background-size: 40%;


    .figure {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      margin: 5rem 0;

      img {
        max-width: 50%;
        margin: 0 1rem;
      }

      figcaption {
        display: block;
        font-size: 1.9rem;
        color: var(--text);
        font-family: "Barlow Light Italic";
        width: 35rem;
        line-height: 140%;
        text-align: left;
        margin-top: 0;
        padding: 1.5rem;
      }

    }

    .figureOne {
      figcaption {
        text-align: right;
      }
    }
  }


  @media only screen and (min-width: 794px) {
    background-position: center 48%;
  }

  @media only screen and (min-width: 800px) {
    background-position: center 50%;
  }

  @media only screen and (min-width: 891px) {
    background-position: center 52%;
    background-size: 37%;
  }



  @media only screen and (min-width: 1024px) {
    padding: 7rem;
    background-image: url('/images/line2.svg');
    background-position: center 53%;
    background-size: 40rem 55rem;

    .figure {
      margin: 6rem 0;

      img {
        max-width: 50%;
      }

      figcaption {
        font-size: 1.9rem;
        width: 40rem;
      }
    }
  }

  @media only screen and (min-width: 1440px) {
    padding: 8rem;

    .figure {
      margin: 7rem 0;

      img {
        max-width: 50%;
      }

      figcaption {
        font-size: 1.9rem;
        width: 40rem;
      }
    }
  }
}


.platform {
  margin: 6rem 0;

  .carouselImage {
    border-radius: .8rem;
    width: 100%;
    height: 100%;
  }

  figcaption {
    text-align: center;
    margin-top: 1rem;
    font-family: "Barlow Regular Italic";

    span {
      font-family: "Barlow SemiBold Italic";
      color: black;
    }
  }

  .features {
    .subtitle {
      margin: 7rem 0 4rem;
    }

    .cardWrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1.2rem;
    }

    .feature {
      background-color: white;
      padding: 1.7rem 2.5rem;
      border-radius: var(--border-radius);

      .featureTitle {
        font-size: 1.8rem;
        color: var(--accent-secondary);
        margin: 0 0 1rem;
      }

      .featureText {
        font-size: 1.8rem;
        margin: 0;
      }
    }

    .ctaPlatform {
      width: 100%;
      margin: 5rem auto 8rem;
      padding: 2rem;
      background-color: white;
      display: grid;
      place-items: center;
      border-radius: var(--border-radius);

      h2 {
        margin-bottom: 3rem;

        span {
          color: var(--accent-secondary);
        }


      }

      .featuresButtons {
        display: flex;
      }

    }
  }

  @media only screen and (min-width: 600px) {

    .features .cardWrapper {
      grid-template-columns: 1fr 1fr;
    }

    .features {
      .ctaPlatform {
        width: 90%;
        margin: 5rem auto 8rem;
        padding: 4rem 2rem;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    padding: 6rem 0;
  }

  @media only screen and (min-width: 1024px) {
    padding: 7rem 0 0;

    .features .cardWrapper {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .features {
      .ctaPlatform {
        width: 100%;
      }
    }
  }

  @media only screen and (min-width: 1440px) {
    padding: 8.4rem 0 0;
  }


}

.table {
  background-color: white;
  border-radius: var(--border-radius);
  padding: 4rem;
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  margin-bottom: 7rem;
}

.table tr {
  border-bottom: 1px solid #f9f9f9;
}

.table thead tr {
  border-bottom: 2px solid #f9f9f9;
}

.table td,
.table th {
  padding: 1rem;

  &:nth-child(2) {
    color: var(--accent);
  }
}

.table th {
  text-align: left;
  font-size: 1.6rem;
  padding: 1.5rem 2rem;
  font-family: "Barlow Regular";
  color: var(--accent-secondary);

  &:nth-child(2) {
    font-family: "Barlow Medium";
  }
}

.table td p {
  font-size: 1.6rem;
  margin: 0;
  color: inherit;
}

@media screen and (max-width: 991px) {
  .table {
    margin: 0 auto 8rem;
    width: 100%;
    border-spacing: 0;
  }

  .table thead {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    overflow: hidden;
  }

  .table tbody,
  .table tr,
  .table th,
  .table td {
    display: block;
    padding: 0;
    text-align: left;
    white-space: normal;
  }

  .table tr td,
  .table tr th {
    padding: 2em 1em;
    vertical-align: top;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .table th[scope="row"] {
    width: 100%;
    text-align: center;
    display: block;
    background-color: var(--accent-secondary);
    color: white;
    padding: 2rem 1rem;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }

  .table td[data-header]:before {
    content: attr(data-header);
    display: block;
    width: 50%;
    color: #000000;
    font-weight: bold;
  }

  .table td[data-header]>* {
    display: block;
    width: 50%;
    float: right;
    padding-left: 1em;
    margin-top: 0;
  }

  .table td[data-header]:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    border-right: 1px solid #EDF0F1;
    padding-bottom: 200%;
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .table {
    margin-bottom: 8rem;
  }
}

@media only screen and (min-width: 1024px) {
  .table {
    margin-bottom: 9rem;
  }
}

@media only screen and (min-width: 1440px) {
  .table {
    margin-bottom: 10rem;
  }
}


.blueApp {
  margin: 6rem 0;

  .contentWrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    .mockupWrapper {
      margin: 3rem 0;
    }
  }

  @media only screen and (min-width: 768px) {

    .contentWrapper {
      flex-direction: row;
      justify-content: center;

      .mockupWrapper {
        margin-left: 10rem;
      }
    }

  }

  @media only screen and (min-width: 768px) {
    padding: 6rem;
  }

  @media only screen and (min-width: 1024px) {
    padding: 7rem;
  }

  @media only screen and (min-width: 1440px) {
    padding: 8rem;
  }
}
