.Root {
  position: absolute;
  display: flex;
  width: 100%;
  max-width: 112rem;
  padding-left: 15%;
  justify-content: flex-start;
}

.ViewportPosition {
  position: absolute;
  display: flex;
  width: 100%;
  top: 100%;
  left: 0;
  z-index: 100;
  perspective: 2000px;
  justify-content: flex-start;
  padding-left: 3.5%;
  padding-top: 1rem;
}

.MenuList {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 4px;
  border-radius: 6px;
  list-style: none;
  margin: 0;
}

.Trigger,
.Link {
  all: unset;
  padding: 8px 8px;
  outline: none;
  user-select: none;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  font-size: 1.4rem;
  color: var(--text);
  &:focus {
    box-shadow: 0 0 0 2px var(--background-color);
  }
  &:hover {
    background-color: var(--background-color)
  }
}

.Trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
}

.Link {
  display: block;
  text-decoration: none;
  font-size: 15px;
  line-height: 1;
}

.Content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  animation-duration: 250ms;
  animation-timing-function: ease;
  &[data-motion='from-start'] {
    animation-name: enterFromLeft;
  }
  &[data-motion='from-end'] {
    animation-name: enterFromRight;
  }
  &[data-motion='to-start'] {
    animation-name: exitToLeft;
  }
  &[data-motion='to-end'] {
    animation-name: exitToRight;
  }
  @media only screen and (min-width: 600px) {
    width: auto;
  }
}

.Indicator {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 10px;
  top: 100%;
  overflow: hidden;
  z-index: 1;
  transition: width, transform 250ms ease;
  &[data-state='visible'] {
    animation: fadeIn 200ms ease;
  }
  &[data-state='hidden'] {
    animation: fadeOut 200ms ease;
  }
}

.Viewport {
  position: relative;
  transform-origin: top center;
  margin-top: 10px;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  height: var(--radix-navigation-menu-viewport-height);
  transition: width, height, 300ms ease;
  &[data-state='open'] {
    animation: scaleIn 200ms ease;
  }
  &[data-state='closed'] {
    animation: scaleOut 200ms ease;
  }
  @media only screen and (min-width: 600px) {
    width: var(--radix-navigation-menu-viewport-width);
  }
}

.List {
  display: grid;
  padding: 22px;
  margin: 0;
  column-gap: 10px;
  list-style: none;
  @media only screen and (min-width: 600px) {
    &:global(.one) {
      width: 400px;
      grid-template-columns: 1fr;
    }
    &:global(.two) {
      width: 600px;
      grid-auto-flow: column;
      grid-template-rows: repeat(2, 1fr);
    }
  }
}

.ListItemLink {
  all: unset;
  cursor: pointer;
  display: block;
  outline: none;
  text-decoration: none;
  user-select: none;
  padding: 12px;
  border-radius: 6px;
  font-size: 15px;
  line-height: 1;
  color: black;
  &:focus {
    box-shadow: 0 0 0 2px var(--oceanteal);
  }
  &:hover {
    background-color: var(--background-color);
  }
}

.ListItemHeading {
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 5px;
  color: var(--accent);
}

.ListItemText {
  all: unset;
  color: var(--text);
  line-height: 1.4;
  font-weight: initial;
}

.Callout {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, var(--purple-9) 0%, var(--indigo-9) 100%);
  border-radius: 6px;
  padding: 25px;
  text-decoration: none;
  outline: none;
  user-select: none;
  &:focus {
    box-shadow: 0 0 0 2px var(--violet-7);
  }
}

.CalloutHeading {
  color: white;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 16px;
  margin-bottom: 7px;
}
.CalloutText {
  all: unset;
  color: var(--mauve-4);
  font-size: 14px;
  line-height: 1.3;
}

.CaretDown {
  position: relative;
  color: var(--violet-10);
  top: 1px;
  transition: transform 250ms ease;
  [data-state='open'] > & {
    transform: rotate(-180deg);
  }
}

.Arrow {
  position: relative;
  top: 70%;
  background-color: white;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border-top-left-radius: 2px;
}

@keyframes enterFromRight {
  from {
    opacity: 0;
    transform: translateX(200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes enterFromLeft {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes exitToRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(200px);
  }
}

@keyframes exitToLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-200px);
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: rotateX(-30deg) scale(0.9);
  }
  to {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
}

@keyframes scaleOut {
  from {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
  to {
    opacity: 0;
    transform: rotateX(-10deg) scale(0.95);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

