.arrowLeft,
.arrowRight {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 50%;
  background-color: var(--accent-secondary);
  color: white;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);

    &:after {
      position: absolute;
      display: block;
      content: "";
      width: 3rem;
      height: 3rem;
      background-color: white;
      mask-image: url('/images/arrow.png');
      mask-size: 2.5rem;
      mask-repeat: no-repeat;
      mask-position: center;
    }

    &:hover {
      transform: scale(1.02) translateY(-50%);
      opacity: 0.95;
    }
}

.arrowLeft {
  left: 1rem;
    &:after {
      transform: rotate(180deg);
    }
}

.arrowRight {
  right: 1rem;
}

.carousel {
  position: relative;
  border-radius: .8rem;
}

.carouselImageWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: .8rem;
  max-height: 57rem;
}

.carouselImage {
  border-radius: .8rem;
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 57rem;
  background-color: #ececec;

  &:hover {
    cursor: pointer;
  }
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  z-index: 100;
  pointer-events: auto;
  transform: none;

  .carouselImage {
    max-height: 108rem;
  }

  .crossIcon {
    position: fixed;
    right: 2rem;
    top: 2rem;
    width: 3rem;
    height: 3rem;
    background-color: white;
    mask-image: url('/images/cross.png');
    mask-size: 2.5rem;
    mask-repeat: no-repeat;
    mask-position: center;
    padding: 1.5rem;

    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 1024px) {

  .overlay {
    .carousel {
      box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
    }

    .carouselImageWrapper {
      max-width: 80vw;
    }

  }
}
