.hamburgerWrapper {
  position: relative;
}

.hamburger {
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  background-color: #D9E7EB;
  transition: background-color 0.3s ease;
  display: grid;
  place-content: center;
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.hamburger::before,
.hamburger::after {
  content: "";
  display: block;
  width: 2rem;
  height: 0.2rem;
  background-color: var(--accent);
  position: absolute;
  transition: transform 0.3s ease;
}

.hamburger::before {
  top: 1.6rem; 
  left: 50%;
  transform: translateX(-50%); 
}

.hamburger::after {
  top: 2.5rem; 
  left: 50%;
  transform: translateX(-50%); 
}

.hamburger.open::before, .hamburger.open::after {
  background-color: var(--accent-secondary);
}

.hamburger.open::before {
  transform: translateX(-50%) rotate(45deg); 
  top: 2rem; 
}

.hamburger.open::after {
  transform: translateX(-50%) rotate(-45deg); 
  top: 2rem; 
}

.hamburgerMenu {
  position: fixed;
  width: 100vw;
  max-width: 50rem;
  height: calc(100vh - 6.6rem);
  background-color: var(--accent);
  top: calc(6.6rem);
  right: calc(50%);
  transform: translateX(50%);

  display: grid;
  place-content: center left;

  & a {
    color: white;
    text-decoration: none;
    margin: 1rem 0;
    font-size: 2.1rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

.list {
  margin: 2rem 5rem 0;
  text-align: left;

  .listTitle {
    margin-top: 0;
    color: var(--accent-secondary);
    font-size: 2.1rem;
    color: white;
  }

  .listItem {
    margin: 1rem 0;
    list-style-type: none;
  }

  .listItem a {
    color: #e9e9e9;
  }

  .loginLink {
    width: fit-content;
    font-size: 2.1rem;
    background-color: var(--accent-secondary);
    margin-top: 2rem;
    font-size: 1.8rem;
    padding: 1rem 1.8rem;
    border-radius: .7rem;
  }

  &:nth-child(3) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
}
